import React from "react";
import styled from "styled-components";
import { FacebookLogo, InstagramLogo, TokTokLogo, TwitterLogo } from "./util";

type Props = {
  className?: string;
};

const SocialLinks: React.FC<Props> = ({ className }) => {
  return (
    <SocialLinksWrapper className={className || ""}>
      <a id="fb" href="https://www.facebook.com/robert.powers.9655" target="_blank" rel="noreferrer">
        {FacebookLogo()}
      </a>
      <a id="in" href="https://www.instagram.com/bert_builds/" target="_blank" rel="noreferrer">
        {InstagramLogo()}
      </a>
      <a id="tk" href="https://www.tiktok.com/@bertbuilds" target="_blank" rel="noreferrer">
        {TokTokLogo()}
      </a>
      <a id="tw" href="https://twitter.com/powers9990" target="_blank" rel="noreferrer">
        {TwitterLogo()}
      </a>
    </SocialLinksWrapper>
  );
};

export default SocialLinks;

const SocialLinksWrapper = styled.div`
  display: flex;
  align-items: center;
  z-index: 10;
  position: relative;
  svg,
  a {
    position: relative;
    height: 75px;
    width: 75px;
  }
  a::after {
    transition: opacity 0.1s linear;
    opacity: 0;
    z-index: -1;
    content: "";
    position: absolute;
    left: 3px;
    top: 3px;
    height: calc(100% - 6px);
    width: calc(100% - 6px);
    background-color: white;
    border-radius: 15px;
  }
  svg path {
    transition: fill 0.1s linear;
  }
  a {
    margin: 0px 5px;
  }
  a:hover::after {
    opacity: 1;
  }
  #fb svg:hover path {
    fill: #3b5998;
  }
  #tk svg:hover path {
    fill: #010101;
  }
  #tw svg:hover path {
    fill: #1da1f2;
  }
  #in svg:hover path {
    fill: #30618a;
  }
  @media only screen and (max-width: 500px) {
    svg,
    a {
      height: 50px;
      width: 50px;
    }
  }
`;
