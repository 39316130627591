import React, { useState } from "react";
import styled from "styled-components";
import { BasicProduct, Product, VariantProduct } from "../types";
import {Link} from "react-router-dom"

type Props = {
  product: Product;
};

const Card: React.FC<Props> = ({ product }) => {
  const [imageLoading, setImageLoading] = useState(!!product.images[0]);
  const [imageError, setImageError] = useState(!product.images[0]);

  return (
    <CardStyled color={"#D20000"}>
      <div className="thumbnailWrapper">
        {!imageError && (
          <img
            src={product.images[0]}
            alt={
              product.type === "basic"
                ? (product as BasicProduct).name
                : (product as VariantProduct).baseName + " thumbnail"
            }
            onLoad={() => setImageLoading(false)}
            onError={() => setImageError(true)}
          />
        )}
        {imageError && (
          <div className="imgError">
            <svg
              width="78"
              height="78"
              viewBox="0 0 78 78"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M73 6L6 73"
                stroke="#000000"
                stroke-width="5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 6L73 73"
                stroke="#000000"
                stroke-width="5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        )}
        {imageLoading && (
          <div className="imgLoading">
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="512.000000pt"
              height="512.000000pt"
              viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none"
              >
                <path
                  d="M515 5104 c-184 -39 -360 -176 -442 -344 -78 -159 -73 -6 -73 -2200
0 -2194 -5 -2041 73 -2200 58 -119 168 -229 287 -287 159 -78 6 -73 2200 -73
2194 0 2041 -5 2200 73 119 58 229 168 287 287 78 159 73 6 73 2200 0 2194 5
2041 -73 2200 -58 119 -168 229 -287 287 -159 78 -5 73 -2207 72 -1692 -1
-1978 -3 -2038 -15z m4090 -317 c78 -36 146 -104 182 -181 l28 -61 3 -920 c1
-506 1 -1005 0 -1110 l-3 -190 -345 425 c-190 234 -362 444 -383 467 -118 129
-298 167 -457 98 -61 -27 -105 -68 -783 -744 -560 -559 -720 -714 -731 -706
-8 6 -133 108 -277 228 -151 125 -286 229 -318 245 -50 24 -67 27 -161 27
-172 0 -111 43 -973 -692 l-87 -75 2 1474 3 1473 28 60 c49 104 146 184 251
205 22 4 923 7 2001 6 l1960 -1 60 -28z m-768 -1750 c12 -7 238 -279 503 -605
l481 -592 -3 -633 -3 -632 -28 -60 c-36 -78 -104 -146 -181 -182 l-61 -28
-1985 0 -1985 0 -61 28 c-77 36 -145 104 -181 182 l-28 60 -3 315 -2 315 436
370 c628 533 581 495 615 495 17 0 39 -5 50 -11 11 -6 158 -125 327 -266 169
-140 321 -264 337 -274 37 -23 96 -24 141 -3 19 9 356 341 770 757 406 408
749 750 763 759 30 21 67 22 98 5z"
                />
                <path
                  d="M1490 4481 c-153 -29 -277 -96 -390 -210 -142 -143 -211 -306 -211
-501 0 -52 5 -117 10 -145 55 -279 263 -496 543 -567 109 -27 288 -22 388 12
198 67 342 193 430 375 62 128 75 184 75 320 -1 84 -6 129 -23 190 -70 245
-251 429 -497 505 -78 24 -249 35 -325 21z m253 -316 c189 -57 318 -266 286
-465 -31 -198 -193 -347 -390 -358 -180 -10 -333 82 -412 248 -40 83 -49 208
-22 291 39 119 119 213 222 261 104 49 205 56 316 23z"
                />
              </g>
            </svg>
          </div>
        )}
      </div>
      <div className="details">
        <h3>
          {product.name}
        </h3>
        <p>{product.description.short}</p>
        <button>
          <span><Link to={"/product/" + product.SKU}>View Product</Link></span>
        </button>
      </div>
    </CardStyled>
  );
};

export default Card;

const CardStyled = styled.div`
  width: 400px;
  height: 380px;
  position: relative;
  margin: 0 auto;
  z-index: 10;
  div.thumbnailWrapper {
    position: relative;
    height: 225px;
    .imgLoading {
      position: absolute;
      height: 200px;
      border-radius: 5px;
      width: 90%;
      top: 15px;
      left: 5%;
      border: 2px solid black;
      animation-duration: 6s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;
      background: #fff;
      background: linear-gradient(
        to right,
        #eeeeee 8%,
        #dddddd 18%,
        #eeeeee 33%
      );
      svg {
        position: absolute;
        height: 75px;
        width: 75px;
        opacity: 0.25;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
      }
    }
    .imgError {
      position: absolute;
      height: 200px;
      border-radius: 5px;
      width: 90%;
      top: 15px;
      left: 5%;
      border: 2px solid black;
      background: #ddd;
      svg {
        position: absolute;
        height: 75px;
        width: 75px;
        opacity: 0.25;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
      }
    }
    img {
      object-fit: cover;
      width: 90%;
      margin: 15px 5% 0 5%;
      border-radius: 5px;
      border: 2px solid black;
      height: 200px;
    }
    &::after {
      content: "";
      background-color: ${(p: { color: string }) => p.color};
      position: absolute;
      width: 90%;
      height: 200px;
      top: 22px;
      left: calc(5% + 7px);
      z-index: -1;
      border-radius: 5px;
      border: 2px solid black;
    }
  }
  div.details {
    margin: 0 5%;
    width: 90%;
    text-align: center;
    position: relative;
    h3 {
      font-size: 22px;
      margin: 14px 0 7px;
    }
    button {
      position: relative;
      color: inherit;
      border: none;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      background-color: black;
      padding: 7px 30px;
      margin-top: 10px;
      border-radius: 5px;
      span::after {
        content: "";
        background-color: ${(p: { color: string }) => p.color};
        position: absolute;
        width: 97%;
        height: 90%;
        top: 0px;
        left: 1px;
        z-index: -1;
        border-radius: 5px;
        border: 2px solid black;
        transition: top 0.3s ease-in-out, left 0.3s ease-in-out;
      }
      span {
        a {
          text-decoration: none;
        }
        font-family: "Lexend Exa", sans-serif;
        color: white;
        font-size: 20px;
        text-shadow: 1.5px 1.5px 0px ${(p: { color: string }) => p.color};
      }
    }
    button:hover span::after {
      top: 7px;
      left: 7px;
    }
    button:active {
      background-color: #2e2e2e;
    }
  }
  @keyframes placeHolderShimmer {
    0% {
      background-position: 0px 0;
    }
    100% {
      background-position: 100em 0;
    }
  }
  @media only screen and (max-width: 900px) {
    & {
      width: 350px;
    }
  }
`;
