import styled from "styled-components";
import Card from "./components/card";
import Youtube from "./components/Youtube";
import BannerWrapper from "./components/Banner";
import "./basic.css";
import Jumbotron from "./components/Jumbotron";
import Footer from "./components/footer";
import { StoreState } from "./redux/store";
import { connect, ConnectedProps } from "react-redux";
import { fetchProducts } from "./redux/action/productActions";
import { useEffect } from "react";
import { hash, Product } from "./types";
import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import ProductPage from "./components/ProductPage";
import Cart from "./components/Cart";
import Confirm from "./components/Confirm";
import { fetchYoutube } from "./redux/action/youtubeActions";
import Lookup from "./components/Lookup";
import { fetchSettings } from "./redux/action/settingsActions";

let connector = connect(
  (state: StoreState) => ({
    productsData: state.productsData,
    settingsData: state.settingsData
  }),
  { fetchProducts, fetchYoutube, fetchSettings }
);

type ReduxProps = ConnectedProps<typeof connector>;

// A custom hook that builds on useLocation to parse
// the query string for you.
function usePath() {
  return useLocation().pathname.split("/");
}

const App: React.FC<ReduxProps> = ({ fetchProducts, fetchYoutube, productsData, settingsData, fetchSettings }) => {
  let path = usePath();

  useEffect(() => {
    fetchProducts();
    fetchYoutube();
    fetchSettings();
  }, [fetchProducts, fetchYoutube, fetchSettings]);

  if (productsData.loading || settingsData.loading) {
    return <h1>Content loading...</h1>;
  }
  if (productsData.error) {
    return <h1>There was an error: {productsData.error + ""}</h1>;
  }

  let sortedProducts: hash<Product[]> = {
    Other: [],
  };
  let sortedProductKeys: string[] = ["Other"];
  let featuredProducts: Product[] = [];

  productsData.products.forEach((product) => {
    if (product.isFeatured) featuredProducts.push(product);
    if (!product.category) {
      sortedProducts["Other"].push(product);
      return;
    }
    if (sortedProducts[product.category])
      sortedProducts[product.category].push(product);
    else {
      sortedProducts[product.category] = [product];
      sortedProductKeys = [product.category, ...sortedProductKeys];
    }
  });

  return (
    <ContentWrapper>
      <BannerWrapper />
      <Cart/>
      <Switch>
        <Route exact path="/">
          <Jumbotron />
          <h1 className="sectionHeader">Featured Products</h1>
          <CardCollection>
            {featuredProducts.map((item) => (
              <Card product={item} key={item.SKU} />
            ))}
          </CardCollection>
          <Youtube />
          {sortedProductKeys.map((key) => {
            return (
              <React.Fragment key={key}>
                <h1 className="sectionHeader">{key}</h1>
                <CardCollection>
                  {sortedProducts[key].map((item) => (
                    <Card product={item} key={item.SKU} />
                  ))}
                </CardCollection>
              </React.Fragment>
            );
          })}
        </Route>
        <Route exact path="/product/:id">
          <ProductPage sku={path[path.length-1]}/>
        </Route>
        <Route path="/confirm">
          <Confirm/>
        </Route>
        <Route path="/lookup">
          <Lookup/>
        </Route>
      </Switch>
      <Footer />
    </ContentWrapper>
  );
};

export default connector(App);

let CardCollection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 5% 15px;
  width: 90%;
  gap: 16px;

  @media only screen and (max-width: 1375px) {
    & {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media only screen and (max-width: 800px) {
    & {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

let ContentWrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  background-color: white;
  .sectionHeader {
    display: table;
    border-bottom: 5px solid #d20000;
    text-align: center;
    margin: 30px auto 20px auto;
    padding-bottom: 7px;
  }
`;
