import { CartEntry } from "../../types";

export enum CartActionTypes {
  ADD = "addcart",
  REMOVE = "removecart",
  UPDATE = "updatecart",
}

export const addItemToCart =
  (entry: CartEntry) =>
  (dispatch: (a: { type: CartActionTypes; payload: CartEntry }) => void) => {
    dispatch({
      type: CartActionTypes.ADD,
      payload: entry,
    });
  };

export const updateCartItem =
  (entry: CartEntry) =>
  (dispatch: (a: { type: CartActionTypes; payload: CartEntry }) => void) => {
    dispatch({
      type: CartActionTypes.UPDATE,
      payload: entry,
    });
  };

export const removeItemToCart =
  (entry: CartEntry) =>
  (dispatch: (a: { type: CartActionTypes; payload: CartEntry }) => void) => {
    dispatch({
      type: CartActionTypes.REMOVE,
      payload: entry,
    });
  };
