import { PayloadAction } from "@reduxjs/toolkit";
import { Settings, SettingsData } from "../../types";
import { SettingsActionTypes } from "../action/settingsActions";

const initState: SettingsData = {
  loading: true,
  error: false,
  settings: null,
};

function settingsReducer(
  state: SettingsData = initState,
  action: PayloadAction<Settings, string>
): SettingsData {
  switch (action.type) {
    case SettingsActionTypes.START:
      return { ...state, loading: true };
    case SettingsActionTypes.SUCCESS:
      return { loading: false, settings: action.payload, error: false };
    case SettingsActionTypes.ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

export default settingsReducer;
