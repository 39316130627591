import React, { useEffect } from "react";
import styled from "styled-components";
import { ReactSpringCarouselItem, useSpringCarousel } from "react-spring-carousel-js";
import { connect, ConnectedProps } from "react-redux";
import { StoreState } from "../redux/store";

let connector = connect(
  (state: StoreState) => ({
    settingsData: state.settingsData,
  }),
  {}
);

type ReduxProps = ConnectedProps<typeof connector>;

const Jumbotron:React.FC<ReduxProps> = ({settingsData}) => {

  let jumboData:ReactSpringCarouselItem[] = [];

  if (settingsData.settings) {
    jumboData = settingsData.settings.jumbotron.map((item, index) => {
      return {
        id: "item-" + index,
        renderItem: (
          <a href={item.url}>
            <img
              src={item.img}
              alt=""
              onMouseDown={(e) => e.preventDefault()}
            />
          </a>
        ),
      }
    })
  }

  const { carouselFragment, slideToNextItem } = useSpringCarousel({
    withLoop: true,
    items: jumboData,
  });

  useEffect(() => {
      var interval = setInterval(slideToNextItem, 10000);
      return () => {
          clearInterval(interval)
      }
  })

  return <JumbotronWrapper>{carouselFragment}</JumbotronWrapper>;
};

export default connector(Jumbotron);

let JumbotronWrapper = styled.div`
  width: 100%;
  a {
    width: 100%;
  }
  img {
    width: 100%;
    max-height: 800px;
    object-fit: cover;
    object-position: top;
  }
  @media only screen and (max-width: 1200px) {
    img {
      max-height: 700px;
    }
  }

  @media only screen and (max-width: 1000px) {
    img {
      max-height: 600px;
    }
  }

  @media only screen and (max-width: 800px) {
    img {
      max-height: 400px;
    }
  }

  @media only screen and (max-width: 600px) {
    img {
      max-height: 300px;
    }
  }

  @media only screen and (max-width: 400px) {
    img {
      max-height: 200px;
    }
  }
`;
