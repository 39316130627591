import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import SocialLinks from "./SocialLinks";
import {
  Square,
} from "./util";

const Footer = () => {
  return (
    <FooterWrapper>
      <div>
        <h1>Bert Build's</h1>
        <h3>
          <span className="supportingText">“NOTHING TO IT JUST TO DO IT!”</span>
        </h3>
      </div>
      <p><Link to="/lookup">Track Orders Here</Link></p>
      <SocialLinks className="links"/>
      <div className="info">
        <div>
          <p>Copyright 2021</p>
        </div>
        <div>
          {Square()}
          <p>🔒 <a href="https://squareup.com/us/en/security">Secure Checkout</a></p>
        </div>
        <div>
          <p>Made with 🧡 by <a href="https://andrivard4.github.io">Andrew</a></p>
          <p>Software Acknowledgements</p>
        </div>
      </div>
    </FooterWrapper>
  );
};

export default Footer;

let FooterWrapper = styled.footer`
  width: 100%;
  background: #d20000;
  background: linear-gradient(180deg, #BC1414 0%, #D20000 30%, #D20000 65%, #BC1414 100%);
  min-height: 300px;
  color: white;
  text-align: center;
  padding: 20px 30px 0;
  .links {
    justify-content: center;
  }
  .info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: end;
    & > div:first-child {
        text-align:left;
    }
    & > div:last-child {
        text-align:right;
    }
  }
  & > div {
      padding: 20px 0;
  }
  @media only screen and (max-width: 750px) {

    .info {
      grid-template-columns: repeat(1, 1fr);
      & > div *{
        text-align:center;
        margin: 5px 0;
      }
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
`;
