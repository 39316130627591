import { PayloadAction } from "@reduxjs/toolkit";
import { Youtube, YoutubeData } from "../../types";
import { YoutubeActionTypes } from "../action/youtubeActions";

const initState: YoutubeData = {
  loading: true,
  error: false,
  youtube: {
    banner: "",
    stats: {
      viewCount: 0,
      videoCount: 0,
      subscriberCount: 0,
    },
  },
};

function youtubeReducer(
  state: YoutubeData = initState,
  action: PayloadAction<Youtube, string>
): YoutubeData {
  switch (action.type) {
    case YoutubeActionTypes.START:
      return { ...state, loading: true };
    case YoutubeActionTypes.SUCCESS:
      return { loading: false, youtube: action.payload, error: false };
    case YoutubeActionTypes.ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

export default youtubeReducer;
