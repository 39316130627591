import { PayloadAction } from "@reduxjs/toolkit";
import { CartEntry } from "../../types";
import { CartActionTypes } from "../action/cartActions";

const initState: CartEntry[] = [];

function cartReducer(
  state: CartEntry[] = initState,
  action: PayloadAction<CartEntry>
): CartEntry[] {
  switch (action.type) {
    case CartActionTypes.ADD: {
      let currentItemIndex = state.findIndex(
        (item) =>
          item.sku === action.payload.sku && item.code === action.payload.code
      );
      console.log(currentItemIndex);

      if (currentItemIndex < 0) return [...state, action.payload];
      let copyState = [...state];
      copyState[currentItemIndex].quantity += action.payload.quantity;
      return copyState;
    }
    case CartActionTypes.UPDATE: {
      let currentItemIndex = state.findIndex(
        (item) =>
          item.sku === action.payload.sku && item.code === action.payload.code
      );
      if (currentItemIndex < 0) return state;
      let copyState = [...state];
      copyState[currentItemIndex] = action.payload;
      return copyState;
    }
    case CartActionTypes.REMOVE: {
      let currentItemIndex = state.findIndex(
        (item) =>
          item.sku === action.payload.sku && item.code === action.payload.code
      );
      if (currentItemIndex < 0) return state;
      let copyState = [...state];
      copyState.splice(currentItemIndex, 1);
      return copyState;
    }
    default:
      return state;
  }
}

export default cartReducer;
