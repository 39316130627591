import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useSpringCarousel } from "react-spring-carousel-js";
import styled from "styled-components";
import { StoreState } from "../redux/store";
import { BasicProduct, flatRateShipping, ShippingType, VariantProduct } from "../types";
import { Link, useHistory } from "react-router-dom";
import { addItemToCart } from "../redux/action/cartActions";

let connector = connect(
  (state: StoreState) => ({
    productsData: state.productsData,
  }),
  { addItemToCart }
);

type ReduxProps = ConnectedProps<typeof connector>;

type Props = {
  sku: string;
};

const ProductPage: React.FC<Props & ReduxProps> = ({
  sku,
  productsData,
  addItemToCart,
}) => {
  const [selectedCode, setSelectedCode] = useState<string>("");
  const history = useHistory();
  if (productsData.loading) return <h1>Loading Product</h1>;
  if (productsData.error) return <h1>Error Loading Product</h1>;
  let product = productsData.products.find((product) => product.SKU === sku);
  if (!product) return <h1>Couldn't find requested product!</h1>;
  if (!selectedCode && product.type === "variant") {
    setSelectedCode((product as VariantProduct).defaultCode);
  }

  function handleCartAdd() {
    if (!product) return;
    addItemToCart({
      sku: product?.SKU,
      quantity: 1,
      code: selectedCode || undefined,
    });
    history.push("/");
  }

  let price = 0;
  let selectedVariant;
  if (product.type === "basic") price = (product as BasicProduct).price;
  else if (product.type === "variant") {
    selectedVariant = (product as VariantProduct).variants.find(
      (item) => item.code === selectedCode
    );
    price = selectedVariant?.price || 0.0;
  }

  let shippingMessage = "";
  switch(product.shipping.type) {
    case ShippingType.CALCULATED : {
      shippingMessage = "Shipping for this item is calculated at checkout.";
      break;
    }
    case ShippingType.FREE : {
      shippingMessage = " + free shipping!";
      break;
    }
    case ShippingType.FLAT : {
      shippingMessage = " + $" + (product.shipping as flatRateShipping).flatRate + " shipping";
      break;
    }
  }

  return (
    <ProductWrapper>
      <Link to="/">
        <h4>{"< "} Home</h4>
      </Link>
      <div
        style={
          product.images.length <= 0
            ? { gridTemplateColumns: "1fr", margin: "0 10%" }
            : {}
        }
      >
        {product.images.length > 0 && (
          <div className="slideshow">
            <Slideshow imageURLs={product.images} />
          </div>
        )}
        <div className="details">
          <h1>{product.name}</h1>
          <h3>${price}</h3>
          <p>{shippingMessage}</p>
          {}
          {((selectedVariant && selectedVariant.quantity <= 0) ||
            (!selectedCode && (product as BasicProduct).quantity <= 0)) && (
            <h3 className="stock-message">Out of Stock!</h3>
          )}
          {((selectedVariant &&
            selectedVariant.quantity >= 1 &&
            selectedVariant.quantity <= 5) ||
            (!selectedCode &&
              (product as BasicProduct).quantity >= 1 &&
              (product as BasicProduct).quantity <= 5)) && (
            <h3 className="stock-message">
              Only {(product as BasicProduct).quantity || selectedVariant?.quantity} left!
            </h3>
          )}
          <p>{product.description.short}</p>
          <p>{product.description.long}</p>
          {product.type === "variant" && (
            <RadioSelect>
              {(product as VariantProduct).variants.map((item) => {
                return (
                  <>
                    <input
                      type="radio"
                      name="variant"
                      checked={item.code === selectedCode}
                      onChange={() => setSelectedCode(item.code)}
                      id={item.code}
                    />
                    <label htmlFor={item.code}>
                      <h3>{item.name}</h3>
                      <p>${item.price}</p>
                    </label>
                  </>
                );
              })}
            </RadioSelect>
          )}
          <p>SKU: {product.SKU}</p>
          {product.type === "variant" && <p>Code: {selectedCode}</p>}
          <button
            onClick={handleCartAdd}
            disabled={
              (selectedVariant && selectedVariant.quantity <= 0) ||
              (!selectedCode && (product as BasicProduct).quantity <= 0)
            }
          >
            <span>Add to Cart</span>
          </button>
        </div>
      </div>
    </ProductWrapper>
  );
};

type SlideshowProps = {
  imageURLs: string[];
};

const Slideshow: React.FC<SlideshowProps> = ({ imageURLs }) => {
  const { carouselFragment } = useSpringCarousel({
    withLoop: true,
    items: imageURLs.map((url, index) => {
      return {
        id: index + "",
        renderItem: (
          <img
            src={url}
            alt={"product image " + index}
            onMouseDown={(e) => e.preventDefault()}
          />
        ),
      };
    }),
  });

  return carouselFragment;
};

export default connector(ProductPage);

let RadioSelect = styled.div`
  margin: 15px 50px;
  input {
    display: none;
  }
  label {
    text-align: center;
    p,
    h3 {
      margin: 0;
      padding: 0;
    }
    display: inline-block;
    border: 2px solid #00000030;
    padding: 15px 20px;
    border-radius: 5px;
    margin: 10px;
  }
  input:checked + label {
    border: 2px solid #000000;
  }
  input:hover + label {
    background-color: #0000000a;
    cursor: pointer;
  }
  input:focus + label {
    outline: 2px solid #d20000;
    outline-offset: 3px;
  }
`;

let ProductWrapper = styled.div`
  margin: 30px 0 50px 0;
  min-height: 50vh;
  position: relative;
  z-index: 10;
  text-align: center;
  a,
  h4 {
    text-decoration: none;
    text-align: left;
    padding: 0 30px;
  }
  h1 {
    display: table;
    margin: 0 auto 20px auto;
    border-bottom: 5px solid #d20000;
    padding-bottom: 7px;
  }
  p,
  h3 {
    margin: 0 10% 15px 10%;
  }
  .stock-message {
    color: white;
    background-color: black;
    display: table;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
  }
  & > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    grid-gap: 30px;
    @media only screen and (max-width: 847px) {
      grid-template-columns: 1fr;
    }
  }
  .slideshow img {
    width: 80%;
    margin: 0 10%;
    max-height: 400px;
    object-fit: contain;
    border-radius: 5px;
  }
  button {
    position: relative;
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background-color: black;
    padding: 7px 30px;
    margin-top: 10px;
    text-align: center;
    border-radius: 5px;
    span::after {
      content: "";
      background-color: #d20000;
      position: absolute;
      width: 97%;
      height: 90%;
      top: 0px;
      left: 1px;
      z-index: -1;
      border-radius: 5px;
      border: 2px solid black;
      transition: top 0.3s ease-in-out, left 0.3s ease-in-out;
    }
    span {
      a {
        text-decoration: none;
      }
      font-family: "Lexend Exa", sans-serif;
      color: white;
      font-size: 20px;
      text-shadow: 1.5px 1.5px 0px #d20000;
    }
  }
  button:hover:not(:disabled) span::after {
    top: 7px;
    left: 7px;
  }
  button:active,
  button:disabled {
    background-color: #2e2e2e;
  }
`;
