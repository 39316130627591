import { Settings } from "../../types";

export enum SettingsActionTypes {
  START = "fetchsettingsstart",
  SUCCESS = "fetchsettingssuccess",
  ERROR = "fetchsettingserror",
}

const getSettingsStarted = () => ({
  type: SettingsActionTypes.START,
});

const getSettingsSuccess = (settings: Settings) => ({
  type: SettingsActionTypes.SUCCESS,
  payload: settings,
});

const getSettingsFailed = (error: any) => ({
  type: SettingsActionTypes.ERROR,
  payload: error,
});

export const fetchSettings = () => async (dispatch: Function) => {
  dispatch(getSettingsStarted());
  try {
    const settings = await fetchSettingsHelper();
    dispatch(getSettingsSuccess(settings));
  } catch (err) {
    dispatch(getSettingsFailed(err));
  }
};

async function fetchSettingsHelper() {
  let res = await fetch(`/.netlify/functions/web_data?data=settings`, {
    method: "GET",
    mode: "cors",
    headers: {
      accept: "application/json",
    },
  });
  if (!res.ok) {
    throw new Error("Failed to fetch data");
  }

  let res_parsed = await res.json();

  return res_parsed as Settings;
}
