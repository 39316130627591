import { Product } from "../../types";

export enum ProductActionTypes {
  START = "fetchstart",
  SUCCESS = "fetchsuccess",
  ERROR = "fetcherror",
}

const getProductsStarted = () => ({
  type: ProductActionTypes.START,
});

const getProductsSuccess = (products: Product[]) => ({
  type: ProductActionTypes.SUCCESS,
  payload: products,
});

const getProductsFailed = (error: any) => ({
  type: ProductActionTypes.ERROR,
  payload: error,
});

export const fetchProducts = () => async (dispatch: Function) => {
  dispatch(getProductsStarted());
  try {
    const products = await fetchProductsDetailsHelper();
    dispatch(getProductsSuccess(products));
  } catch (err) {
    dispatch(getProductsFailed(err));
  }
};

async function fetchProductsDetailsHelper() {
  let res = await fetch(`/.netlify/functions/product`, {
    method: "GET",
    mode: "cors",
    headers: {
      accept: "application/json",
    },
  });
  if (!res.ok) {
    throw new Error("Failed to fetch data");
  }
  console.log(res);

  let res_parsed = await res.json();

  return res_parsed.message as Product[];
}
