import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { CartEntry, ProductData, SettingsData, YoutubeData } from "../types";
import rootReducer from "./reducer/root";

export interface StoreState {
  productsData: ProductData;
  youtubeData: YoutubeData;
  cartData: CartEntry[];
  settingsData: SettingsData;
}

const initialState: StoreState = {
  cartData: [],
  settingsData: {
    loading: true,
    error: false,
    settings: null,
  },
  productsData: {
    loading: true,
    products: [],
    error: false,
  },
  youtubeData: {
    loading: true,
    error: false,
    youtube: {
      banner: "",
      stats: {
        viewCount: 0,
        videoCount: 0,
        subscriberCount: 0,
      },
    },
  },
};

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
