/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import styled from "styled-components";
import { StoreState } from "../redux/store";
import { hash } from "../types";


const connector = connect((state:StoreState) => ({
  cartData:state.cartData
}))

type ReduxProps = ConnectedProps<typeof connector>;

type Props = {};

const Checkout: React.FC<Props & ReduxProps> = ({cartData}) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("AL");
  const [zip, setZip] = useState<string>("");

  const [errors, setErrors] = useState<{
    count: number;
    errors: hash<boolean>;
  }>({ count: 0, errors: {} });
  const [backendError, setBackendError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handleOrderSubmit(e: React.SyntheticEvent) {
    e.preventDefault();
    // Check for errors
    if (errors.count !== 0) {
      return;
    }
    setBackendError("");
    setIsLoading(true);
    let res = await fetch(`/.netlify/functions/createOrder`, {
      method: "POST",
      mode: "cors",
      headers: {
        accept: "application/json",
      },
      body : JSON.stringify({
        customer: {
          fName:firstName,
          lName:lastName,
          email,
          phone
        },
        order: {
          street,
          city,
          state,
          zip
        },
        products: cartData
      })
    });
    if (!res.ok) {
      try {
        let error = await res.json();
        setBackendError(error.message);
        console.log(error);

      } catch(e) {
        console.log("unknown Error");

        setBackendError("An unknown error has occurred, try again later.")
      }

      setIsLoading(false);
      return;
    }

    let res_parsed = await res.json();
    if (!res_parsed.url) {
      setBackendError("An unknown error has occurred, try again later.");
      return;
    }
    window.location.replace(res_parsed.url)

  }

  return (
    <CartCheckoutPage>
      <form onSubmit={handleOrderSubmit} id="checkout-form">
        <>
          <h2>Personal info </h2>
          <div id="personal">
            <label id="fName" className={errors.errors["fName"] ? "error" : ""}>
              First Name:
              <input
                disabled={isLoading}
                required={true}
                type="text"
                autoComplete="given-name"
                value={firstName}
                onChange={(e) => {
                  if (
                    !/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i.test(
                      e.target.value
                    )
                  ) {
                    if (!errors.errors.fName)
                      setErrors({
                        count: errors.count + 1,
                        errors: { ...errors.errors, fName: true },
                      });
                  } else {
                    if (errors.errors.fName)
                      setErrors({
                        count: errors.count - 1,
                        errors: { ...errors.errors, fName: false },
                      });
                  }
                  setFirstName(e.target.value);
                }}
              />
            </label>
            <label id="lName" className={errors.errors["lName"] ? "error" : ""}>
              Last Name:
              <input
                disabled={isLoading}
                required={true}
                type="text"
                autoComplete="family-name"
                value={lastName}
                onChange={(e) => {
                  if (
                    !/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i.test(
                      e.target.value
                    )
                  ) {
                    if (!errors.errors.lName)
                      setErrors({
                        count: errors.count + 1,
                        errors: { ...errors.errors, lName: true },
                      });
                  } else {
                    if (errors.errors.lName)
                      setErrors({
                        count: errors.count - 1,
                        errors: { ...errors.errors, lName: false },
                      });
                  }
                  setLastName(e.target.value);
                }}
              />
            </label>
            <label id="email" className={errors.errors["email"] ? "error" : ""}>
              Email:
              <input
                disabled={isLoading}
                required={true}
                type="text"
                autoComplete="email"
                value={email}
                onChange={(e) => {
                  if (
                    !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i.test(
                      e.target.value
                    )
                  ) {
                    if (!errors.errors.email)
                      setErrors({
                        count: errors.count + 1,
                        errors: { ...errors.errors, email: true },
                      });
                  } else {
                    if (errors.errors.email)
                      setErrors({
                        count: errors.count - 1,
                        errors: { ...errors.errors, email: false },
                      });
                  }
                  setEmail(e.target.value);
                }}
              />
            </label>
            <label id="tel" className={errors.errors["tel"] ? "error" : ""}>
              Phone Number:
              <input
                disabled={isLoading}
                required={true}
                type="tel"
                autoComplete="mobile tel"
                value={phone}
                onChange={(e) => {
                  if (
                    !/^[0-9]{10}$|^[0-9]{3}-[0-9]{3}-[0-9]{4}$|^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/.test(
                      e.target.value
                    )
                  ) {
                    if (!errors.errors.tel)
                      setErrors({
                        count: errors.count + 1,
                        errors: { ...errors.errors, tel: true },
                      });
                  } else {
                    if (errors.errors.tel)
                      setErrors({
                        count: errors.count - 1,
                        errors: { ...errors.errors, tel: false },
                      });
                  }
                  setPhone(e.target.value);
                }}
              />
            </label>
          </div>
          <h2>Shipping info</h2>
          <div id="shipping">
            <label
              id="address"
              className={errors.errors["address"] ? "error" : ""}
            >
              Street:
              <input
                disabled={isLoading}
                required={true}
                type="text"
                autoComplete="street-address"
                value={street}
                onChange={(e) => {
                  if (!/^\w+(\s\w+){2,}$/i.test(e.target.value)) {
                    if (!errors.errors.address)
                      setErrors({
                        count: errors.count + 1,
                        errors: { ...errors.errors, address: true },
                      });
                  } else {
                    if (errors.errors.address)
                      setErrors({
                        count: errors.count - 1,
                        errors: { ...errors.errors, address: false },
                      });
                  }
                  setStreet(e.target.value);
                }}
              />
            </label>
            <label id="city" className={errors.errors["city"] ? "error" : ""}>
              City:
              <input
                disabled={isLoading}
                required={true}
                type="text"
                autoComplete="address-level2"
                value={city}
                onChange={(e) => {
                  if (!/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/i.test(e.target.value)) {
                    if (!errors.errors.city)
                      setErrors({
                        count: errors.count + 1,
                        errors: { ...errors.errors, city: true },
                      });
                  } else {
                    if (errors.errors.city)
                      setErrors({
                        count: errors.count - 1,
                        errors: { ...errors.errors, city: false },
                      });
                  }
                  setCity(e.target.value);
                }}
              />
            </label>
            <label id="state">
              State:
              <select
                disabled={isLoading}
                required={true}
                autoComplete="address-level1"
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                }}
              >
                <option value="AL">AL</option>
                <option value="AK">AK</option>
                <option value="AR">AR</option>
                <option value="AZ">AZ</option>
                <option value="CA">CA</option>
                <option value="CO">CO</option>
                <option value="CT">CT</option>
                <option value="DC">DC</option>
                <option value="DE">DE</option>
                <option value="FL">FL</option>
                <option value="GA">GA</option>
                <option value="HI">HI</option>
                <option value="IA">IA</option>
                <option value="ID">ID</option>
                <option value="IL">IL</option>
                <option value="IN">IN</option>
                <option value="KS">KS</option>
                <option value="KY">KY</option>
                <option value="LA">LA</option>
                <option value="MA">MA</option>
                <option value="MD">MD</option>
                <option value="ME">ME</option>
                <option value="MI">MI</option>
                <option value="MN">MN</option>
                <option value="MO">MO</option>
                <option value="MS">MS</option>
                <option value="MT">MT</option>
                <option value="NC">NC</option>
                <option value="NE">NE</option>
                <option value="NH">NH</option>
                <option value="NJ">NJ</option>
                <option value="NM">NM</option>
                <option value="NV">NV</option>
                <option value="NY">NY</option>
                <option value="ND">ND</option>
                <option value="OH">OH</option>
                <option value="OK">OK</option>
                <option value="OR">OR</option>
                <option value="PA">PA</option>
                <option value="RI">RI</option>
                <option value="SC">SC</option>
                <option value="SD">SD</option>
                <option value="TN">TN</option>
                <option value="TX">TX</option>
                <option value="UT">UT</option>
                <option value="VT">VT</option>
                <option value="VA">VA</option>
                <option value="WA">WA</option>
                <option value="WI">WI</option>
                <option value="WV">WV</option>
                <option value="WY">WY</option>
              </select>
            </label>
            <label
              id="postal"
              className={errors.errors["postal"] ? "error" : ""}
            >
              Zip Code:
              <input
                disabled={isLoading}
                required={true}
                type="text"
                autoComplete="postal-code"
                value={zip}
                onChange={(e) => {
                  if (!/^\d{5}(?:[-\s]\d{4})?$/i.test(e.target.value)) {
                    if (!errors.errors.postal)
                      setErrors({
                        count: errors.count + 1,
                        errors: { ...errors.errors, postal: true },
                      });
                  } else {
                    if (errors.errors.postal)
                      setErrors({
                        count: errors.count - 1,
                        errors: { ...errors.errors, postal: false },
                      });
                  }
                  setZip(e.target.value);
                }}
              />
            </label>
          </div>
        </>
      </form>
      {errors.count !== 0 && (
        <p className="ErrorText">
          Please fix errors highlighted in red before continuing.
        </p>
      )}
      {backendError && <p className="ErrorText">{backendError}</p>}
      {isLoading ? (
        <h2>Loading...</h2>
      ) : (
        <button type="submit" id="submit" form="checkout-form">
          <h3>Proceed</h3>
        </button>
      )}
    </CartCheckoutPage>
  );
};

export default connector(Checkout);

const CartCheckoutPage = styled.div`
  .ErrorText {
    color: red;
    text-align: center;
    font-style: italic;
  }
  @media only screen and (max-width: 1300px) {
    button.cart-close {
      right: 5px;
      left: unset;
      top: 5px;
    }
  }
  button#submit {
    margin: 30px calc(50% - 100px) 0;
    width: 200px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color:black;
  }
  form {
    > div {
      display: grid;
      grid-column-gap: 50px;
      grid-row-gap: 20px;
    }
    .error input {
      border-color: red;
    }
    #personal {
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "a b" "c c" "d d";
      @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        grid-template-areas: "a" "b" "c" "d";
      }
      #fName {
        grid-area: a;
      }
      #lName {
        grid-area: b;
      }
      #email {
        grid-area: c;
      }
      #tel {
        grid-area: d;
      }
    }
    #shipping {
      @media only screen and (max-width: 700px) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "a a" "b b" "c d";
      }
      @media only screen and (max-width: 400px) {
        grid-template-columns: 1fr;
        grid-template-areas: "a" "b" "c" "d";
      }
      grid-template-columns: 3fr 1fr 1fr;
      grid-template-areas: "a a a" "b c d";
      #address {
        grid-area: a;
      }
      #city {
        grid-area: b;
      }
      #state {
        grid-area: c;
      }
      #postal {
        grid-area: d;
      }
    }
    h2 {
      padding: 20px 0;
    }
    label {
      text-align: left;
      input,
      select {
        border-radius: 5px;
        width: 100%;
        padding: 10px;
        display: block;
        border: 1px solid black;
        outline: none;
      }
      select {
        appearance: none;
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
        background-repeat: no-repeat, repeat;
        background-position: right 0.7em top 50%, 0 0;
        background-size: 0.65em auto, 100%;
      }
    }
  }
`;
