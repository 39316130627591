import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import styled from "styled-components";
import { removeItemToCart, updateCartItem } from "../redux/action/cartActions";
import { StoreState } from "../redux/store";
import { BasicProduct, VariantProduct } from "../types";
import Checkout from "./Checkout";
import { Cancel, Cart as CartIcon } from "./util";

let connector = connect(
  (state: StoreState) => ({
    cartData: state.cartData,
    productData: state.productsData,
  }),
  { removeItemToCart, updateCartItem }
);

type ReduxProps = ConnectedProps<typeof connector>;

const Cart: React.FC<ReduxProps> = ({
  cartData,
  productData,
  removeItemToCart,
  updateCartItem,
}) => {
  const [cartOpen, setCartOpen] = useState(false);
  const [checkout, setCheckout] = useState(false);

  let totalCost = 0;

  return (
    <>
      {cartOpen ? (
        <CartWrapper>
          <div className="backdrop"></div>
          <div className="content">
            <h1>{checkout ? "Checkout" : "Cart"}</h1>
            <button className="cancel" onClick={() => {setCartOpen(false); setCheckout(false)}}>
              {Cancel()}
            </button>
            {checkout ? (
              <Checkout />
            ) : (
              <>
                {cartData.length <= 0 && <h2>The cart is empty!</h2>}
                {cartData.map((item) => {
                  let product = productData.products.find(
                    (prod) => prod.SKU === item.sku
                  );
                  let selectedVariant = null;
                  if (!product)
                    throw new Error("failed to find product added to cart!");
                  if (item.code && product.type !== "variant")
                    throw new Error("code added to basic item in cart");
                  if (product.type === "variant") {
                    selectedVariant = (product as VariantProduct).variants.find(
                      (vari) => vari.code === item.code
                    );
                    totalCost += item.quantity * (selectedVariant?.price || 0);
                  } else {
                    totalCost +=
                      item.quantity * (product as BasicProduct).price;
                  }
                  return (
                    <CartItem key={item.sku + item.code}>
                      <button
                        className="removeItem"
                        onClick={() => removeItemToCart(item)}
                      >
                        <h3>X</h3>
                      </button>
                      {product.images.length > 0 ? (
                        <img
                          src={product.images[0]}
                          alt={product.name + " thumbnail"}
                        />
                      ) : (
                        <div></div>
                      )}
                      <div>
                        <h2>{product.name}</h2>
                        {selectedVariant && <h3>{selectedVariant.name}</h3>}
                        <h3>
                          $
                          {selectedVariant
                            ? selectedVariant.price
                            : (product as BasicProduct).price}
                        </h3>
                        <h3>
                          <button
                            style={
                              item.quantity > 1 ? {} : { visibility: "hidden" }
                            }
                            onClick={() =>
                              updateCartItem({
                                ...item,
                                quantity: item.quantity - 1,
                              })
                            }
                          >
                            -
                          </button>{" "}
                          {item.quantity}{" "}
                          <button
                            style={
                              item.quantity <= 10
                                ? {}
                                : { visibility: "hidden" }
                            }
                            onClick={() =>
                              updateCartItem({
                                ...item,
                                quantity: item.quantity + 1,
                              })
                            }
                          >
                            +
                          </button>
                        </h3>
                        <p>
                          {product.SKU}
                          {item.code && "-" + item.code}
                        </p>
                      </div>
                    </CartItem>
                  );
                })}
                {cartData.length > 0 && (
                  <div className="cartFooter">
                    <h3>
                      Total: ${totalCost.toFixed(2)}{" "}
                      <span className="sub">+ shipping & tax</span>
                    </h3>
                    <button className="main-action" onClick={()=>setCheckout(true)}>
                      <span>Checkout</span>
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </CartWrapper>
      ) : (
        <CartButton>
          <button onClick={() => setCartOpen(true)}>
            {cartData.length > 0 && <p>{cartData.length}</p>}
            {CartIcon()}
          </button>
        </CartButton>
      )}
    </>
  );
};

export default connector(Cart);

const CartWrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  text-align: center;
  z-index: 100;
  .sub {
    font-size: 14px;
  }
  .cartFooter {
    margin: 30px;
  }
  div.content {
    position: absolute;
    min-height: 300px;
    max-height: 85vh;
    overflow: auto;
    width: 80%;
    margin: 5% 10%;
    left: 0;
    top: 0;
    z-index: 100;
    background-color: white;
    border-radius: 25px;
    padding: 40px;
    h1 {
      display: table;
      border-bottom: 5px solid #d20000;
      text-align: center;
      margin: 0 auto 20px auto;
      padding-bottom: 7px;
    }
    .cancel {
      position: absolute;
      right: 25px;
      top: 25px;
      svg path {
        fill: black;
      }
    }
  }
  div.backdrop {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 50;
    background-color: #000000ba;
  }
  .cart-icon button {
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    right: 25px;
    top: 25px;
    height: 90px;
    width: 90px;
    border-radius: 50%;
    background-color: white;
    filter: drop-shadow(0 0 5px #0000007d);
    svg {
      width: 50px;
      height: 50px;
      path {
        fill: #d20000;
      }
    }
  }
  button.main-action {
    position: relative;
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background-color: black;
    padding: 7px 30px;
    margin-top: 20px;
    border-radius: 5px;
    span::after {
      content: "";
      background-color: #d20000;
      position: absolute;
      width: 97%;
      height: 90%;
      top: 0px;
      left: 1px;
      z-index: -1;
      border-radius: 5px;
      border: 2px solid black;
      transition: top 0.3s ease-in-out, left 0.3s ease-in-out;
    }
    span {
      a {
        text-decoration: none;
      }
      font-family: "Lexend Exa", sans-serif;
      color: white;
      font-size: 20px;
      text-shadow: 1.5px 1.5px 0px #d20000;
    }
  }
  button.main-action:hover span::after {
    top: 7px;
    left: 7px;
  }
  button.main-action:active {
    background-color: #2e2e2e;
  }
  @media only screen and (max-width: 700px) {
    div.content {
      width: 100%;
      height: 100%;
      max-height: 100%;
      margin: 0;
      border-radius: 0;
    }
  }
  @media only screen and (max-width: 600px) {
    div.content {
      padding: 20px 5px;
    }
  }
`;

const CartButton = styled.div`
  button {
    z-index: 100;
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    right: 25px;
    top: 25px;
    height: 90px;
    width: 90px;
    border-radius: 50%;
    background-color: white;
    filter: drop-shadow(0 0 5px #0000007d);
    svg {
      width: 50px;
      height: 50px;
      path {
        fill: #d20000;
      }
    }
    p {
      position: absolute;
      right: -5px;
      top: -5px;
      color: white;
      background-color: #d20000;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      padding-top: 5px;
    }
  }
  @media only screen and (max-width: 750px) {
    button {
      bottom: 25px;
      top: unset;
    }
  }
`;

const CartItem = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
  text-align: center;
  padding: 30px 0;
  margin: 0 100px;
  border-bottom: 3px solid #d20000;
  .removeItem {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  img {
    height: 250px;
    max-width: 400px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  h2 {
    margin-bottom: 14px;
  }

  h3 {
    margin-bottom: 7px;
  }
  @media only screen and (max-width: 1000px) {
    & {
      grid-template-columns: 1fr;
      margin: 0 50px;
    }
  }
`;
