export type Product = BasicProduct | VariantProduct;

export type BasicProduct = ProductProps & BasicProductProps;

export type VariantProduct = VariantProductProps & ProductProps;

export enum ProductType {
  BASIC = "basic",
  VARIANT = "variant",
}

export enum ShippingType {
  FLAT = "flat_rate",
  CALCULATED = "calculated_rate",
  FREE = "free_rate",
}

type ProductProps = {
  warning?: boolean;
  SKU: string;
  images: string[];
  taxable: boolean;
  hidden?: boolean;
  type: ProductType;
  category: string;
  isFeatured: boolean;
  name: string;
  description: {
    short: string;
    long: string;
  };
  shipping: {
    type: ShippingType;
    stacking: boolean;
  } & (flatRateShipping | calculatedRateShipping);
};

export type flatRateShipping = {
  flatRate: number;
};

export type calculatedRateShipping = {
  isLetter: boolean;
  width: number;
  height: number;
  depth: number;
  weight: number;
};

export type BasicProductProps = {
  name: string;
  quantity: number;
  price: number;
};

export type VariantProductProps = {
  baseName: string;
  defaultCode: string;
  variants: {
    code: string;
    price: number;
    name: string;
    quantity: number;
  }[];
};

export type Order = {
  status: string;
  customerID: string;
  trackingID: string;
  totalPrice: number;
  totalTax: number;
  totalShipping: number;
  products: CartEntry[];
  checkoutURL?: string;
  checkoutID?: string;
  shippingTrackingNumber?: string;
  shippingTrackingURL?: string;
  shippingETA?: string;
  deliveredDate?: string;
  errorDetails?: string;
  paidTransactionID?: string;
};

export type ProductData = {
  products: Product[];
  loading: boolean;
  error: any;
};

export type YoutubeData = {
  youtube: Youtube;
  loading: boolean;
  error: any;
};

export type Youtube = {
  banner: string;
  stats: {
    viewCount: number;
    subscriberCount: number;
    videoCount: number;
  };
};

export type CartEntry = {
  sku: string;
  quantity: number;
  code?: string;
  pricePaid?: number;
};

export type Address = {
  street: string;
  city: string;
  zip: string;
  state: string;
};

export type Customer = {
  fName: string;
  lName: string;
  phone: string;
  email: string;
};

export type CustomerData = {
  data: Customer;
  ref: {
    "@ref": {
      id: string;
      collection: string;
    };
  };
  ts: string;
};

export type Jumbotron = {
  url: string;
  img: string;
};

export type Settings = {
  jumbotron: Jumbotron[];
  message: string;
};

export type SettingsData = {
  settings: Settings | null;
  loading: boolean;
  error: any;
};

export type hash<T> = { [key: string]: T };
