import { useSpring, animated, config } from "react-spring";
import React from "react";
import styled from "styled-components";
import { YoutubeLogo } from "../components/util";
import { StoreState } from "../redux/store";
import { connect, ConnectedProps } from "react-redux";

let connector = connect(
  (state: StoreState) => ({
    youtubeData: state.youtubeData,
  })
);

type ReduxProps = ConnectedProps<typeof connector>;

const Youtube: React.FC<ReduxProps> = ({youtubeData }) => {
  const viewCount = useSpring({
    from: { number: 0 },
    number: youtubeData.youtube.stats.viewCount,
    delay: 200,
    config: config.molasses,
  });
  const subscriberCount = useSpring({
    from: { number: 0 },
    number: youtubeData.youtube.stats.subscriberCount,
    delay: 200,
    config: config.molasses,
  });
  const videoCount = useSpring({
    from: { number: 0 },
    number: youtubeData.youtube.stats.videoCount,
    delay: 200,
    config: config.molasses,
  });

  if (youtubeData.error) {
    return <YoutubeContainer>
        <img
          src="https://i.imgur.com/6qRnY64.png"
          alt="Youtube banner"
        />
      <div>
        <div className="yt-logo">
          <a href="https://www.youtube.com/channel/UCQeHXoRd_3riqOT-5j5Wm3Q" target="_blank" rel="noreferrer">
            {YoutubeLogo()}
            <h2>Subscribe</h2>
          </a>
        </div>
      </div>
    </YoutubeContainer>
  }

  return (
    <YoutubeContainer>
      {!youtubeData.loading ? (
        <img
          src={`${youtubeData.youtube.banner}=w1080-fcrop64=1`}
          alt="test"
          onError={(e) => {
            (e.target as HTMLImageElement).onerror = null;
            (e.target as HTMLImageElement).src =
              "https://i.imgur.com/6qRnY64.png";
          }}
        />
      ) : (
        <h2>Loading banner...</h2>
      )}
      <div>
        <div className="yt-logo">
          <a href="https://www.youtube.com/channel/UCQeHXoRd_3riqOT-5j5Wm3Q" target="_blank" rel="noreferrer">
            {YoutubeLogo()}
            <h2>Subscribe</h2>
          </a>
        </div>
        <div>
          {!youtubeData.loading ? (
            <animated.h3>
              {viewCount.number.to((n) => Math.floor(n).toLocaleString())}
            </animated.h3>
          ) : (
            <h3>Loading...</h3>
          )}
          <p>Views</p>
        </div>
        <div>
           {!youtubeData.loading ? (
            <animated.h3>
              {subscriberCount.number.to((n) => Math.floor(n).toLocaleString())}
            </animated.h3>
          ) : (
            <h3>Loading...</h3>
          )}
          <p>Subscribers</p>
        </div>
        <div>
          {!youtubeData.loading ? (
            <animated.h3>
              {videoCount.number.to((n) => Math.floor(n).toLocaleString())}
            </animated.h3>
          ) : (
            <h3>Loading...</h3>
          )}
          <p>Videos</p>
        </div>
      </div>
    </YoutubeContainer>
  );
};

export default connector(Youtube);

let YoutubeContainer = styled.div`
  width: 100%;
  img {
    width: 100%;
    height: 500px;
    margin: 0;
    display: inherit;
    object-fit: cover;
  }
  div {
    padding: 10px 20px;
    h2,
    h3,
    p {
      margin: 0;
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h3 {
        font-size: 24px;
      }
      p {
        font-size: 14px;
        font-weight: 100;
      }
    }
    div.yt-logo {
      position: relative;
    }
    div.yt-logo:hover {
      a {
        z-index: 10;
        svg path {
          fill: #d20000;
        }
        color: #d20000;
      }
      cursor: pointer;
      a::after {
        z-index: -1;
        background-color: #ffffffff;
      }
    }
    div.yt-logo a {
      text-decoration: none;
      color: white;
      transition: color 0.2s linear;
      svg path {
        transition: fill 0.2s linear;
      }
      &::after {
        transition: background-color 0.2s linear;
        content: "";
        position: absolute;
        width: 300px;
        height: 70px;
        border-radius: 15px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #ffffff00;
      }
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      font-family: "Krona One", sans-serif;
      svg {
        margin-right: 15px;
      }
    }
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    width: 100%;
    background-color: #d20000;
    color: white;
    font-family: "Roboto", sans-serif;
  }
  @media only screen and (max-width: 800px) {
    div {
      grid-template-columns: 1fr;
    }
  }
`;
