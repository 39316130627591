import { PayloadAction } from "@reduxjs/toolkit";
import { Product, ProductData } from "../../types";
import { ProductActionTypes } from "../action/productActions";

const initState = {
  loading: true,
  error: false,
  products: [],
};

function productsReducer(
  state: ProductData = initState,
  action: PayloadAction<Product[], string>
): ProductData {
  switch (action.type) {
    case ProductActionTypes.START:
      return { ...state, loading: true };
    case ProductActionTypes.SUCCESS:
      return { loading: false, products: action.payload, error: false };
    case ProductActionTypes.ERROR:
      return { loading: false, products: [], error: action.payload };
    default:
      return state;
  }
}

export default productsReducer;
