import { combineReducers } from "redux";
import cartReducer from "./cartReducer";
import productsReducer from "./productsReducer";
import settingsReducer from "./settingsReducer";
import youtubeReducer from "./youtubeReducer";
let rootReducer = combineReducers({
  productsData: productsReducer,
  youtubeData: youtubeReducer,
  cartData: cartReducer,
  settingsData: settingsReducer,
});

export default rootReducer;
