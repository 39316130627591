import { Youtube } from "../../types";

export enum YoutubeActionTypes {
  START = "fetchyoutubestart",
  SUCCESS = "fetchyoutubesuccess",
  ERROR = "fetchyoutubeerror",
}

const getYoutubeStarted = () => ({
  type: YoutubeActionTypes.START,
});

const getYoutubeSuccess = (youtube: Youtube) => ({
  type: YoutubeActionTypes.SUCCESS,
  payload: youtube,
});

const getYoutubeFailed = (error: any) => ({
  type: YoutubeActionTypes.ERROR,
  payload: error,
});

export const fetchYoutube = () => async (dispatch: Function) => {
  dispatch(getYoutubeStarted());
  try {
    const youtube = await fetchYoutubeHelper();
    dispatch(getYoutubeSuccess(youtube));
  } catch (err) {
    dispatch(getYoutubeFailed(err));
  }
};

async function fetchYoutubeHelper() {
  let res = await fetch(`/.netlify/functions/web_data?data=youtube`, {
    method: "GET",
    mode: "cors",
    headers: {
      accept: "application/json",
    },
  });
  if (!res.ok) {
    throw new Error("Failed to fetch data");
  }

  let res_parsed = await res.json();

  return res_parsed as Youtube;
}
