import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import styled from "styled-components";
import { StoreState } from "../redux/store";
import { Customer, Order, ProductType, VariantProduct } from "../types";

let connector = connect(
  (state: StoreState) => ({
    productsData: state.productsData,
  }),
  {}
);

type ReduxProps = ConnectedProps<typeof connector>;

const Lookup: React.FC<ReduxProps> = ({ productsData }) => {
  const [tracking, setTracking] = useState("");
  const [email, setEmail] = useState("");

  const [result, setResult] = useState<Customer & Order>();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function handleLookup() {
    setResult(undefined);
    if (!email || !tracking) {
      setError("Tracking number and email are required!");
      return;
    }
    setError("");
    setIsLoading(true);
    try {
      let res = await fetch(
        `/.netlify/functions/lookupOrder?email=${encodeURIComponent(
          email
        )}&id=${encodeURIComponent(tracking)}`
      );
      if (!res.ok) {
        setError(
          "Failed to lookup this order, try again later or email us with your tracking ID."
        );
        setIsLoading(false);
        return;
      }
      let data = await res.json();
      if (data.url) window.location.replace(data.url);
      setResult(data);
      setIsLoading(false);
    } catch (e) {}
  }

  return (
    <LookupWrapper>
      <h1>Lookup an Order</h1>
      <label>
        Email:{" "}
        <input
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          disabled={isLoading}
        />
      </label>
      <br />
      <label>
        Tracking Number:{" "}
        <input
          type="email"
          onChange={(e) => setTracking(e.target.value)}
          value={tracking}
          disabled={isLoading}
        />
      </label>
      <br />
      {error && <p>{error}</p>}
      {isLoading ? (
        <p>Loading Results...</p>
      ) : (
        <button onClick={handleLookup}>
          <span>Submit</span>
        </button>
      )}
      {result && (
        <>
          <h2>Status of {result.trackingID}</h2>
          {result.status === "paid" && <p>Paid and awaiting fulfillment.</p>}
          {result.status === "processing" && (
            <p>Order is processing before being shipped.</p>
          )}
          {result.status === "shipping" && (
            <>
              <p>Order is shipped!</p>
              <p>
                Tracking ID:
                {result.shippingTrackingURL ? (
                  <a href={result.shippingTrackingURL}>
                    {result.shippingTrackingNumber}
                  </a>
                ) : (
                  result.shippingTrackingNumber
                )}
              </p>
            </>
          )}
          {result.status === "complete" && (
            <>
              <p>
                Order is complete! If you haven't received your order or have
                questions please email us.
              </p>
            </>
          )}
          {result.status === "canceled" && (
            <>
              <p>
                Order is canceled. If you think this is an error please email
                us.
              </p>
            </>
          )}
          {result.status === "error" && (
            <>
              <p>
                Your order is having some issues. Please email us for more info.
              </p>
            </>
          )}
          <h2>Receipt</h2>
          <table>
            <thead>
              <tr>
                <td>
                  <h4>Item</h4>
                </td>
                <td>
                  <h4>Quantity</h4>
                </td>
                <td>
                  <h4>Price</h4>
                </td>
              </tr>
            </thead>
            <tbody>
              {result.products.map((prod) => {
                let product = productsData.products.find(
                  (item) => item.SKU === prod.sku
                );
                if (!product) {
                  return (
                    <tr>
                      <td>
                        <p>
                          {prod.sku}
                          {prod.code && " - " + prod.code}
                        </p>
                      </td>
                      <td>
                        <p>{prod.quantity}</p>
                      </td>
                      <td>
                        <p>$ {prod.pricePaid?.toFixed(2)}</p>
                      </td>
                    </tr>
                  );
                }
                return (
                  <tr>
                    <td>
                      <p>
                        {product?.name}
                        {product.type === ProductType.VARIANT &&
                          " - " +
                            (product as VariantProduct).variants.find(
                              (Var) => Var.code === prod.code
                            )?.name}
                      </p>
                    </td>
                    <td>
                      <p>{prod.quantity}</p>
                    </td>
                    <td>
                      <p>$ {prod.pricePaid?.toFixed(2)}</p>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td>
                  <p>Shipping</p>
                </td>
                <td>
                  <p>$ {result.totalShipping.toFixed(2)}</p>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <p>Tax</p>
                </td>
                <td>
                  <p>$ {result.totalTax.toFixed(2)}</p>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <p>
                    <b>Total</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>
                      ${" "}
                      {(
                        result.totalPrice +
                        result.totalTax +
                        result.totalShipping
                      ).toFixed(2)}
                    </b>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </LookupWrapper>
  );
};

export default connector(Lookup);

let LookupWrapper = styled.div`
  position: relative;
  z-index: 0;
  text-align: center;
  margin-bottom: 40px;
  table {
    width: 80%;
    margin: 0 10%;
    h4 {
      padding: 30px 0 14px;
    }
    p {
      padding: 5px 0;
    }
  }
  h1,
  h2 {
    margin: 30px 0 20px;
  }
  button {
    position: relative;
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background-color: black;
    padding: 7px 30px;
    margin-top: 25px;
    border-radius: 5px;
    span::after {
      content: "";
      background-color: #d20000;
      position: absolute;
      width: 97%;
      height: 90%;
      top: 0px;
      left: 1px;
      z-index: -1;
      border-radius: 5px;
      border: 2px solid black;
      transition: top 0.3s ease-in-out, left 0.3s ease-in-out;
    }
    span {
      a {
        text-decoration: none;
      }
      font-family: "Lexend Exa", sans-serif;
      color: white;
      font-size: 20px;
      text-shadow: 1.5px 1.5px 0px #d20000;
    }
  }
  input {
    margin: 15px 0 0 0;
  }
  button:hover span::after {
    top: 7px;
    left: 7px;
  }
  button:active {
    background-color: #2e2e2e;
  }
`;
