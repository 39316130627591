import React from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Check } from './util'

const Confirm = () => {
    const query = useQuery();

    return (
        <ConfirmWrapper>
            <h1>Order Confirmed</h1>
            {Check()}
            <p>Your order has been placed! We have sent an email to <strong>{query.get("email")}</strong> with your receipt.</p>
            <p>Your tracking number is <strong>{query.get("id")}</strong>.</p>
            <p>You can track your order anytime on our <Link to="/lookup">lookup page here</Link>.</p>
            <p>Reach out to us at <strong>Robert@email.com</strong> if you need help with this order.</p>

        </ConfirmWrapper>
    )
}

export default Confirm

let ConfirmWrapper = styled.div `
text-align:center;
min-height: 600px;
margin: 40px 20px;
svg {
    height: 200px;
    width: 200px;
}
p {
    margin: 5px 0;
}
`

function useQuery() {
  return new URLSearchParams(useLocation().search);
}