import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { StoreState } from "../redux/store";
import SocialLinks from "./SocialLinks";
import { Cancel, CompanyLogo } from "./util";

let connector = connect(
  (state: StoreState) => ({
    settingsData: state.settingsData,
  }),
  {}
);

type ReduxProps = ConnectedProps<typeof connector>;

const Banner:React.FC<ReduxProps> = ({settingsData}) => {
  const [showMessage, setShowMessage] = useState(settingsData.error || settingsData.settings?.message);

  const history = useHistory();

  return (
    <>
      <BannerWrapper>
        <div className="logo" onClick={()=>history.push("/")}>
          {CompanyLogo()}
          <div>
            <h1>Bert Build's</h1>
            <h3>
              <span className="supportingText">
                “NOTHING TO IT JUST TO DO IT!”
              </span>
            </h3>
          </div>
        </div>
        <SocialLinks className="links" />
        {!showMessage && (
          <button
            onClick={() => setShowMessage(!showMessage)}
            className="open"
          ></button>
        )}
      </BannerWrapper>
      <MessageWrapper showing={showMessage}>
        <p>
          {settingsData.error && "We are having issues loading some data, so the site might look off, but will still work."}
          {settingsData.settings && settingsData.settings.message}
        </p>
        <button onClick={() => setShowMessage(!showMessage)}>{Cancel()}</button>
      </MessageWrapper>
    </>
  );
};

export default connector(Banner);

let MessageWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #b70000;
  display: grid;
  grid-template-columns: auto 70px;
  align-items: flex-start;
  max-height: ${(p: { showing: boolean }) => (p.showing ? "75px" : "0px")};
  transition: max-height 0.3s linear;
  svg {
    height: 30px;
    margin: 10px;
  }
  p {
    color: white;
    text-align: center;
    margin: 15px 30px 10px;
  }
  @media only screen and (max-width: 900px) {
    max-height: ${(p: { showing: boolean }) => (p.showing ? "125px" : "0px")};
  }
`;
let BannerWrapper = styled.div`
  position: relative;
  .open {
    position: absolute;
    width: 40px;
    height: 5px;
    border-radius: 3px;
    background-color: white;
    right: 35px;
    bottom: 5px;
  }
  padding: 10px 30px;
  h1 {
    font-family: "Krona One", sans-serif;
  }
  width: 100%;
  background: #d20000;
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  .logo {
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    svg {
      height: 100px;
      border-right: 2px solid white;
      margin-right: 15px;
    }
  }
  .links {
    justify-content: flex-end;
  }

  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    justify-items:center;
    grid-gap: 24px;
  }
`;
